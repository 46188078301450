import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["newPhrase", "phraseList", "phraseListTitle", "addPhrase"];

  displayNewPhrase(event) {
    const newPhrasePillHtml = this._responseBodyFor(event);
    this.phraseListTarget.insertAdjacentHTML('afterbegin', newPhrasePillHtml);
    this.newPhraseTarget.value = '';
    this._showNonEmptyState();
  }

  displayNewPhraseError(event) {
    const errorHtml = this._responseBodyFor(event);
    this.newPhraseTarget.insertAdjacentHTML('afterend', errorHtml);
    this.newPhraseTarget.setAttribute("aria-invalid", "true");
    this.addPhraseTarget.setAttribute("disabled", "true");
  }

  removePhraseElement(event) {
    const phrasePillComponent = event.target.closest('.pill-component');
    phrasePillComponent.remove();
    this._clearErrors();
    if (this.phraseListTarget.children.length == 0) {
      this._showEmptyState();
    }
  }

  removePhraseError(event) {
    const errorHtml = this._responseBodyFor(event);
    this.newPhraseTarget.insertAdjacentHTML('afterend', errorHtml);
    const phrasePillComponent = event.target.closest('.pill-component');
    phrasePillComponent.setAttribute("aria-invalid", "true");
  }

  enableAddPhraseButton(event) {
    this.addPhraseTarget.removeAttribute("disabled");
    this._clearErrors();
  }

  _showEmptyState() {
    if (!this.phraseListTitleTarget.classList.contains(this._emptyStateClass())) {
      this.phraseListTitleTarget.classList.add(this._emptyStateClass());
    }
    this.phraseListTitleTarget.innerHTML = this.phraseListTitleTarget.dataset.emptyStateTitle;
  }

  _showNonEmptyState() {
    this.phraseListTitleTarget.classList.remove(this._emptyStateClass());
    this.phraseListTitleTarget.innerHTML = this.phraseListTitleTarget.dataset.statefulTitle;
  }

  _emptyStateClass() {
    return this.phraseListTitleTarget.dataset.emptyStateTitleClass;
  }

  _clearErrors() {
    this.newPhraseTarget.removeAttribute("aria-invalid");
    const formErrorElement = this.element.querySelector(".form-error");
    if (formErrorElement) {
      formErrorElement.remove();
    };
  }

  _responseBodyFor(event) {
    return event.detail[2].response;
  }
}
