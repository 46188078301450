/**
 * Enhances Turbo frames with a `fetch-error` event
 * allowing to:
 *  - provide users with feedback on network failures
 *  - harmonize handling of network and server-returned errors
 * Also adds a `data-response-error` attribute to hook some specific styles
 */

// `turbo-frame`s don't (yet) dispatch an event when hitting a NetworkError
// due to an unreachable server. We're having to dig a little deep due to them
// keeping their internals very private :(. Hopefully, they'll respond nicely to
// this issue raise for the occasion: https://github.com/hotwired/turbo/issues/462 :)
const frame = document.createElement("turbo-frame");
const controllerPrototype = Object.getPrototypeOf(frame.delegate);
const originalRequestErrored = controllerPrototype.requestErrored;
controllerPrototype.requestErrored = function (request, error) {
  dispatchFetchError(this.element, { request, error });
  return originalRequestErrored.apply(this, arguments);
};

document.addEventListener("turbo:before-fetch-response", (event) => {
  if (!event.detail.fetchResponse.response.ok) {
    dispatchFetchError(event.target, event.detail);
  }
});

function dispatchFetchError(turboFrameElement, detail) {
  turboFrameElement.dispatchEvent(
    new CustomEvent("turbo:fetch-error", {
      bubbles: true,
      cancelable: true,
      detail,
    })
  );
}

// Mark frames with errors for fetching
document.addEventListener("turbo:fetch-error", (event) => {
  event.target.setAttribute('data-response-error', '');
});

document.addEventListener("turbo:before-fetch-request", (event) => {
  event.target.removeAttribute('data-response-error');
});
