import { Controller } from "stimulus";

export default class extends Controller {

  dismiss(event) {
    event.preventDefault();
    const parent = this.element.parentElement;
    // Bit of a mouthfull, but works in browsers that do not support `.remove()`
    parent.removeChild(this.element);
  }
}
