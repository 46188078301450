import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "button"]

  connect() {
    this.buttonTarget.disabled = true;

    this.inputTarget.addEventListener('input', (event) => {
      if (this.inputTarget.value == "") {
        this.buttonTarget.disabled = true;
      } else {
        this.buttonTarget.disabled = false;
      }
    });
  } 
}