import { Controller } from "stimulus"
import Choices from "choices.js"

export default class extends Controller {
  static targets = ["select"]

  conditionallyShowDropdown () {
    const results = document.querySelector(".choices__list[role=\"listbox\"]")

    if (this.minimumInputLengthBeforeResultsAreShown > 0) {
      if (this.search.input.value.length >= this.minimumInputLengthBeforeResultsAreShown) {
        results.classList.remove("hidden")
      } else {
        results.classList.add("hidden")
      }
    }
  }

  connect() {
    const dataset = this.element.dataset
    this.minimumInputLengthBeforeResultsAreShown = dataset["minimumInputLengthBeforeResultsAreShown"] || 3
    this.search = new Choices(this.selectTarget)
    this.selectTarget.addEventListener('showDropdown', this.conditionallyShowDropdown.bind(this))
    this.selectTarget.addEventListener('search', this.conditionallyShowDropdown.bind(this))
  }
}
