import { BasePlugin } from "@uppy/core";

export default class ConfirmNavigation extends BasePlugin {
  constructor(uppy, opts = {}) {
    super(uppy, opts);
    this.id = opts.id || "Caption.Ed Confirm navigaton";
    this.type = "caption.ed";

    this.message = opts.message || "Are you sure?";

    this.registerListener = this.registerListener.bind(this);
    this.unregisterListener = this.unregisterListener.bind(this);
    this.handleFileRemoval = this.handleFileRemoval.bind(this);
  }

  get listener() {
    if (!this._listener) {
      this._listener = navigationConfirmationListener(this.message);
    }
    return this._listener;
  }

  // Use arrow function to have access to `this`
  // when passed as an event listener
  registerListener() {
    // Use event listener to allow multiple parts of the app
    // to also ask whether it's OK to navigate out if necessary
    addEventListener("beforeunload", this.listener, {
      capture: true,
    });
  };

  unregisterListener() {
    removeEventListener("beforeunload", this.listener, {
      capture: true,
    });
  }

  handleFileRemoval() {
    if (Object.values(this.uppy.getState().files).every(file => file.progress.uploadComplete)) {
      this.unregisterListener();
    }
  }

  install() {
    this.uppy
      .on("upload", this.registerListener)
      .on("complete", this.unregisterListener)
      .on("error", this.unregisterListener)
      .on("file-removed", this.handleFileRemoval);
  }

  uninstall() {
    this.uppy
      .off("upload", this.registerListener)
      .off("complete", this.unregisterListener)
      .off("error", this.unregisterListener)
      .off("file-removed", this.handleFileRemoval);
  }
}

function navigationConfirmationListener(message) {
  return function confirmBeforeNavigation(event) {
    event.preventDefault();
    return (event.returnValue = message);
  }
}
