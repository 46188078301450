import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "button", "hideable" ]

  toggleMenu(event) {
    event.preventDefault();
    const activePresent = this.buttonTarget.classList.contains("change-version__toggle--active");
    this.buttonTarget.classList.toggle("change-version__toggle--active", !activePresent);
    this.hideableTarget.hidden = !this.hideableTarget.hidden;
  }
}