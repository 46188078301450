import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["collapsibleRow"]

  toggle(event) {
    this.collapsibleRowTargets[event.currentTarget.dataset.index].classList.toggle("hidden")
    Array.from(event.currentTarget.children).forEach((el) => el.classList.toggle("hidden"))
  }
}
