/** @jsx h */
import { h } from 'preact'

const ICONS = {
  audio: require("../../../../../../../node_modules/@phosphor-icons/core/assets/regular/speaker-simple-high.svg?raw"),
  video: require("../../../../../../../node_modules/@phosphor-icons/core/assets/regular/film-strip.svg?raw"),
  unknown: require("../../../../../../../node_modules/@phosphor-icons/core/assets/regular/cube.svg?raw")
}

const ICON_CLASSES = {
  audio: 'transcription-icon--audio',
  video: 'transcription-icon--video',
  unknown: 'transcription-icon--unknown'
}

export default function FileIcon({file,...attributes}) {
  return <span
    {...attributes}
    innerHTML={iconFor(file)}>
    <span class="sr-only">{typeOf(file)}</span>
  </span>
}

function typeOf(file) {
  const typeFromMime = file.type.split('/')[0];
  if (typeFromMime in ICONS) {
    return typeFromMime
  }

  return "unknown"
}

function iconFor(file) {
  const fileType = typeOf(file);
  return ICONS[fileType]
    // Expand the viewbox of the icon a bit to give it some space
    // and make it roud
    .replace(
      /viewBox=".*?"/,
      `viewBox="-58 -58 380 380" class="w-10 h-10 rounded-full ${ICON_CLASSES[fileType]}"`
    )
}
