import { Controller } from "stimulus";
import { Chart, registerables } from "chart.js";
import HTMLLegendPlugin from "./chartjs_controller/plugins/html_legend";
Chart.register(...registerables);

export default class extends Controller {
  static targets = ["canvas", "legend"];
  static values = { chartOptions: Object };

  connect() {
    this.buildChart();
  }

  buildChart() {
    var ctx = this.canvasTarget.getContext("2d");
    this.chart = new Chart(ctx, {
      plugins: [
        this.hasLegendTarget && HTMLLegendPlugin(this.legendTarget)
      ],
      ...this.chartOptionsValue
    });
  }

  toggleLegendItem(event) {
    // Pie charts legend items represent individual pieces of data
    // rather than whole datasets, so they're toggled differently
    if (event.target.dataset.legendType == 'data') {
      // At the time the event is handled, the `checked` value
      // represents the value after update by the user
      if (event.target.checked) {
        this.chart.show(0, parseInt(event.target.value))
      } else {
        this.chart.hide(0, parseInt(event.target.value))
      }
    } else {
      this.chart.setDatasetVisibility(
        parseInt(event.target.value),
        event.target.checked
      );
    }
    this.chart.update();
  }
}
