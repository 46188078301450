import Carousel from 'stimulus-carousel'

export default class extends Carousel {
  get defaultOptions() {
    return {
      loop: true,
      speed: 2000,

      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },

      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },

      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
    }
  }
}