import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "form"]

  connect() {
    this.inputTarget.addEventListener('change', (_event) => {
      this.formTarget.submit();
    });
  } 
}