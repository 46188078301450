// Based on https://codepen.io/GeoffreyCrofte/pen/zYrxKRe
// https://www.creativejuiz.fr/blog/en/tutorials/one-time-code-input-sms-code-keyboard-suggestion-ios-android

import {Controller} from 'stimulus';

const KEYCODE_SHIFT = 16;
const KEYCODE_TAB = 9;
const KEYCODE_META = 224;
const KEYCODE_OPTION = 18;
const KEYCODE_CONTROL = 17;
const KEYCODE_BACKSPACE = 8;
const KEYCODE_LEFT = 37;


export default class extends Controller {
  static targets = ["input"]

  get firstInput() {
    return this.inputTargets[0]
  }

  connect() {

    this.inputTargets.forEach(input => {

      input.addEventListener('input', splitNumber)
      /**
       * Control on keyup to catch what the user intent to do.
       * I could have check for numeric key only here, but I didn't.
       */
      input.addEventListener("keyup", function (event) {
        // Break if Shift, Tab, CMD, Option, Control.
        if (
          event.keyCode === KEYCODE_SHIFT ||
          event.keyCode == KEYCODE_TAB ||
          event.keyCode == KEYCODE_META ||
          event.keyCode == KEYCODE_OPTION ||
          event.keyCode == KEYCODE_CONTROL
        ) {
          return;
        }

        // On Backspace or left arrow, go to the previous field.
        if (
          (event.keyCode === KEYCODE_BACKSPACE ||
            event.keyCode === KEYCODE_LEFT) &&
          event.target.previousElementSibling &&
          event.target.previousElementSibling.tagName === "INPUT"
        ) {
          event.target.previousElementSibling.select();
          // Any other keypress will move to the field after
        } else if (
          event.keyCode !== KEYCODE_BACKSPACE &&
          event.target.nextElementSibling
        ) {
          event.target.nextElementSibling.select();
        }
        // If the target is populated to quickly, value length can be > 1
        if (event.target.value.length > 1) {
          splitNumber(event);
        }
      });

      input.addEventListener('focus', (event) => {
        // If the focus element is the first one, do nothing
        if (event.target === this.firstInput) return;

        // If value of input 1 is empty, focus it.
        if (this.firstInput.value == "") {
          this.firstInput.focus();
        }

        // If value of a previous input is empty, focus it.
        // To remove if you don't wanna force user respecting the fields order.
        if (event.target.previousElementSibling.value == "") {
          event.target.previousElementSibling.focus();
        }
      })
    })
  }
}

function splitNumber(event) {
  let data = event.data || event.target.value; // Chrome doesn't get the e.data, it's always empty, fallback to value then.
  if (!data) return; // Shouldn't happen, just in case.
  if (data.length === 1) return; // Here is a normal behavior, not a paste action.

  populateFrom(event.target, data);
}

/**
 * Populates the given inputElement with the first character
 * of the data and moves on to its next sibling
 * @param {Element} inputElement
 * @param {String} data
 */
function populateFrom(inputElement, data) {
  inputElement.value = data[0]; // Apply first item to first input
  data = data.substring(1); // remove the first char.
  if (inputElement.nextElementSibling && data.length) {
    // Do the same with the next element and next data
    populateFrom(inputElement.nextElementSibling, data);
  } else {
    // Move focus to next empty element unless we reached the end
    if (inputElement.value) {
      (inputElement.nextElementSibling || inputElement).focus();
    } else {
      inputElement.focus()
    }
  }
}
