const clientCredentials = () => {
  const [client_id, client_secret] = document.querySelector('meta[name="api_credentials"]').getAttribute('content').split(":")
  return { client_id, client_secret }
}

const API_HEADERS = { "Accept": "application/vnd.captioned.v1" }

export const apiRoot = () => {
  return document.querySelector('meta[name="api_root"]').getAttribute('content')
}

export const authenticate = () => {
  return new Promise((resolve, reject) => {
    const params = new URLSearchParams({
      ...clientCredentials(),
      grant_type: 'assertion',
      provider: 'application_access_token',
      assertion: getAssertionToken(),
      scope: 'write_media write_transcriptions',
    })

    fetch(`/oauth/token?${params}`, {
      "method": "POST",
    })
    .then((response) => response.json())
    .then((accessToken) => {
      storeAccessToken(accessToken)
      resolve(accessToken)
    })
    .catch(reject);
  });
}

export const apiHeaders = (accessToken) => ({
  ...API_HEADERS,
  Authorization: `Bearer ${accessToken.access_token}`,
  "Content-Type": "application/json",
})

const getAssertionToken = () => {
  return document.querySelector('meta[name="application_access_token"]').getAttribute('content')
}

const storeAccessToken = (value) => {
  window.localStorage.setItem("authentication/access_token/dashboard", value)
}
