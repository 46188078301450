export async function metadata(file) {
  return {
    type: file.type,
    size: file.size,
    duration: await mediaDuration(file)
  }
}

const MAXIMUM_FILE_SIZE_TO_ESTIMATE_DURATION = 261000000;

export function mediaDuration(file) {
  return new Promise((resolve) => {
    const player = playerFor(file);
    const reader = new FileReader();

    if (file.size > MAXIMUM_FILE_SIZE_TO_ESTIMATE_DURATION) return resolve();

    reader.onload = (event) => {
      player.src = event.target.result;
      player.addEventListener("durationchange", () => {
        resolve(player.duration);
      });
      player.addEventListener("error", () => {
        resolve();
      });
    };

    reader.onerror = () => {
      resolve();
    };

    reader.readAsDataURL(file);
  });
}

function playerFor(file) {
  if (file.type.startsWith("audio/")) {
    return document.createElement("audio");
  } else if (file.type.startsWith("video/")) {
    return document.createElement("video");
  } else {
    throw new Error("Unsupported file type");
  }
}
