/** @jsx h */
import classNames from 'classnames';
import { h } from 'preact';

const DISCLOSURE_ICON = require("../../../../../../node_modules/@phosphor-icons/core/assets/regular/caret-down.svg?raw");

export function DisclosureToggle({expanded, ...attributes}) {
  return <button
    aria-expanded={expanded}
    {...attributes}
    class={classNames('disclosure-toggle action action--icon', attributes['class'])}
    innerHTML={DISCLOSURE_ICON}
    aria-label="Toggle upload list"
    >
  </button>
}
