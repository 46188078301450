import { Controller } from "stimulus"
import { get as getButtonText, update as updateButtonText } from "../utilities/button_text";

export default class extends Controller {
  static targets = ['submitBtn', 'form', 'cardErrors'];
  static values = {
    publishableKey: String,
    setupIntent: String,
  };

  connect() {
    this.stripe = Stripe(this.publishableKeyValue);
    this.setupStripeCardElement();

    this.submitBtnTarget.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();

      this.submitBtnTarget.disabled = true;
      const previousButtonText = getButtonText(this.submitBtnTarget);
      updateButtonText(this.submitBtnTarget, 'Updating...');

      this.stripe.confirmCardSetup(this.setupIntentValue, {
        payment_method: {
          card: this.card,
        },
      }).then((result) => {
        if (result.error) {
          this.cardErrorsTarget.textContent = result.error.message;
          this.submitBtnTarget.disabled = false;
          updateButtonText(this.submitBtnTarget, previousButtonText);
        } else {
          this.formTarget.submit();
        }
      });
    });
  }

  setupStripeCardElement() {
    const elements = this.stripe.elements();
    const card = elements.create("card");
    card.mount("#card-element");

    card.on("change", (event) => {
      if (event.error) {
        this.cardErrorsTarget.textContent = event.error.message;
      } else {
        this.cardErrorsTarget.textContent = "";
      }
    });

    this.card = card;
  }
}
