import { Controller } from "stimulus"
import Dinero from 'dinero.js'

export default class extends Controller {
  static targets = ["product", "productTotal", "netTotal", "taxTotal", "total"]

  changeQuantity(event) {
    const priceId = event.currentTarget.dataset.priceId
    const product = this.productTargets.find(el => el.dataset['priceId'] == priceId)
    product.dataset.quantity = event.currentTarget.value || 0
    const total = this.totalForProduct(product)
    const productTotalTarget = this.productTotalTargets.find(el => el.dataset['priceId'] == priceId)
    productTotalTarget.innerText = total.toFormat()
    this.updateTotals()
  }

  totalForProduct(product) {
    const { unitAmount, currency, quantity } = product.dataset
    return Dinero({ amount: parseInt(unitAmount, 10), currency: currency }) .multiply(quantity)
  }

  updateTotals() {
    const totals = this.productTargets.map((product) => this.totalForProduct(product))
    const netTotal = totals.reduce((accumulator, nextTotal) => accumulator.add(nextTotal))
    const taxTotal = netTotal.percentage(this.element.dataset.taxPercent)
    const total = netTotal.add(taxTotal)

    this.netTotalTarget.innerText = netTotal.toFormat()
    this.taxTotalTarget.innerText = taxTotal.toFormat()
    this.totalTarget.innerText = total.toFormat()
  }
}
