import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "replace" ]

  updateSelector(event) {
    event.preventDefault();
    event.stopPropagation();

    const [, , xhr] = event.detail;
    this.replaceTarget.innerHTML = xhr.responseText;
    document.querySelector(".notice-list").innerHTML = "";
  }

  showError(event) {
    event.preventDefault();
    event.stopPropagation();

    const [, , xhr] = event.detail;
    document.querySelector(".notice-list").innerHTML = xhr.responseText;
  }
}