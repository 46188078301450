import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["numberField", "field", "button"]

  connect() {
    this.buttonTarget.disabled = true;

    this.numberFieldTargets.forEach(numberField => {
      numberField.addEventListener('input', (event) => {
        const numberValues = this.numberFieldTargets.map(target => target.value);
        if (numberValues.includes('')) {
          this.buttonTarget.disabled = true;
        } else {
          this.buttonTarget.disabled = false;
          this.fieldTarget.value = numberValues.join('');
        }

        if (numberField.value) {
          const nextInput = numberField.nextElementSibling;
          if (nextInput) {
            nextInput.focus();
          }
        }
      });

      numberField.addEventListener('paste', (event) => {
        event.stopPropagation();
        event.preventDefault();
        const clipboardData = event.clipboardData.getData('Text');
        if (clipboardData && clipboardData.length == 6) {
          this.numberFieldTargets.forEach((element, index) => {
            element.value = clipboardData[index];
            if (index == 5) {
              element.focus();
              this.buttonTarget.disabled = false;
            }
          });
        } else {
          event.target.value == clipboardData;
        }
      });
    })
  } 
}
