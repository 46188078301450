/** @jsx h */
import { file } from '@babel/types';
import classNames from 'classnames';
import { h } from 'preact'
import UppyContext from './uppy_context';

const ICON = require("../../../../../../node_modules/@phosphor-icons/core/assets/regular/x.svg?raw");

export function CancelAllAction({...attributes}) {
  return <UppyContext.Consumer>
    {uppy =>
      <button
      {...attributes}
      class={classNames('action action--icon', attributes['class'])}
      innerHTML={ICON}
      aria-label="Cancel all"
      onClick={() => cancelAll(uppy)}
      />
    }
  </UppyContext.Consumer>
}

function cancelAll(uppy) {
  if (!allFilesHaveUploadedOrErrored(uppy) && !confirm('Are you sure you want to cancel all uploads?')) {
    return;
  }
  uppy.cancelAll();
}

function allFilesHaveUploadedOrErrored(uppy) {
  return Object.values(uppy.getState().files).every(file => file.progress.uploadComplete || file.error)
}
