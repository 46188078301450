import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['formInput', 'choiceList'];
  static values = {
    always: Boolean,
  };

  connect() {
    this.initValue();
    this.choiceElements.click(
      (evt) => this.selectOption($(evt.currentTarget))
    );
  }

  initValue() {
    const checkedElem = $(this.choiceListTarget).find('input[type=checkbox]:checked');

    if (checkedElem.length === 1) {
      this.selectOption(checkedElem.first());
    } else if (checkedElem.length > 1) {
      console.log('Custom choices error: multiple initial selections...');
    } else if (this.alwaysValue) {
      this.selectOption(this.choiceElements.first());
    }
  }

  setValue(value) {
    this.formInputTarget.value = value;
  }

  selectOption(elem) {
    this.choiceElements.prop('checked', false);
    elem.prop('checked', true);

    this.setValue(elem.data('value'));
  }

  get choiceElements() {
    return $(this.choiceListTarget).find('input[type=checkbox]');
  }
}
