/** @jsx h */
import classNames from 'classnames';
import { h } from 'preact'
import UppyContext from '../uppy_context';

const CANCEL_ICON = require("../../../../../../../node_modules/@phosphor-icons/core/assets/regular/x-circle.svg?raw");

export default function CancelAction({file, ...attributes}) {
  return <UppyContext.Consumer>
    {uppy => <button
    {...attributes}
    class={classNames('action action--icon', attributes['class'])}
    innerHTML={CANCEL_ICON}
    aria-label="Cancel upload"
    onClick={() => removeFile(uppy, file)}>
  </button>}
  </UppyContext.Consumer>
}

function removeFile(uppy, file) {
  if (!hasCompletedOrErrored(file) && !confirm('Are you sure you want to cancel that upload?')) {
    return;
  }
  uppy.removeFile(file.id);
}

function hasCompletedOrErrored(file) {
  return file.progress.uploadComplete || file.error;
}
