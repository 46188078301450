import { Controller } from "stimulus";

export default class extends Controller {

  get tippy() {
    return this.element._tippy;
  }

  dismiss(event) {
    this.tippy.hide();
    if (event.target.getAttribute('data-tippy-content-focus') == "false") {
      this.tippy.reference.focus();
    }
  }
}
