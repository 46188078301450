import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "password", "show", "hide" ]

  connect() {
    this.hide()
  }

  toggle(event) {
    event.preventDefault();
    if (this.passwordTarget.type == "password") {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.showTarget.style.display = "none";
    this.hideTarget.style.display = "flex";
    this.passwordTarget.type = "text";
  }

  hide() {
    this.showTarget.style.display = "flex";
    this.hideTarget.style.display = "none";
    this.passwordTarget.type = "password";
  }
}
