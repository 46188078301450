/** @jsx h */
import classNames from 'classnames';
import { h } from 'preact'
import ProgressBar from './progress_bar.jsx';

const COMPLETE_ICON = require("../../../../../../../node_modules/@phosphor-icons/core/assets/regular/check-circle.svg?raw");
const ERROR_ICON = require("../../../../../../../node_modules/@phosphor-icons/core/assets/regular/warning-circle.svg?raw");

export default function UploadStatus({file, ...attributes}) {

  attributes = rootAttributes(attributes);

  if (uploadComplete(file)) {
    return <small {...attributes}>
      <span role="presentation" class="inline-block w-3 h-3 text-caption.ed-bright" innerHTML={COMPLETE_ICON}></span>
      <span class="sr-only">Complete</span>
    </small>
  } else if(hasError(file)) {
    return <small
      {...attributes}
      class={classNames('flex items-center space-x-0.25',attributes['class'])}
      >
      <span role="presentation" class="inline-block w-3 h-3 text-red-500 relative bottom-[0.5px]" innerHTML={ERROR_ICON}></span>
      <span class="text-white">Error</span>
    </small>
  } else if (uploadStarted(file)) {
    return <ProgressBar
      max={file.progress.bytesTotal}
      progress={file.progress.bytesUploaded}
      {...attributes}
    />
  } else {
    return <small {...attributes}>Preparing...</small>
  }
}

function rootAttributes(attributes) {
  return {
    ...attributes,
    class: classNames('upload-status', attributes['class'])
  }
}

function uploadStarted(file) {
  return file.progress.uploadStarted;
}

function uploadComplete(file) {
  return file.progress.uploadComplete && file.meta.apiCallsFinished;
}

function hasError(file) {
  return file.error;
}
