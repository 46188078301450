import { Controller } from "stimulus";
export default class extends Controller {
  connect() {
    this.listener = this.toggle.bind(this);
    this.element.addEventListener('click', this.listener)
  }
  disconnect() {
    this.element.removeEventListener('click', this.listener);
  }

  toggle() {
    event.preventDefault(); // In case it's set on a link
    this.element.setAttribute('aria-expanded', this.element.getAttribute('aria-expanded') !== 'true')
  }
}
