import { Controller } from "stimulus"
import { getCSRFToken } from '../utilities/api'

export default class extends Controller {
  static targets = ["displayValue", "editInput", "editForm", "displayValueText", "editIcon"]

  connect() {
    if (this.element.dataset.editable === "false") return

    this.enableEditMode = this.enableEditMode.bind(this)
    this.editMode = false
    this.displayValueTarget.addEventListener('click', this.enableEditMode)
    this.editIconTarget.classList.remove('hidden')
  }

  disconnect() {
    this.displayValueTarget.removeEventListener('click', this.enableEditMode)
  }

  enableEditMode() {
    if (!this.editMode) {
      this.displayValueTarget.classList.add('hidden')
      this.editFormTarget.classList.remove('hidden')
    }
  }

  disableEditMode() {
    if (!this.editMode) {
      this.displayValueTarget.classList.remove('hidden')
      this.editFormTarget.classList.add('hidden')
    }
  }

  submit() {
    fetch(`/transcription_sessions/${this.element.dataset.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken()
      },
      body: JSON.stringify({ transcription_session: { title: this.editInputTarget.value }})
    });

    this.displayValueTextTarget.innerText = this.editInputTarget.value
    this.disableEditMode()
  }
}
