import { Controller } from "stimulus";
/**
 * Properly empties an element when the last of its children
 * gets removed so that the `:empty` CSS pseudo element triggers
 * appropriately
 */
export default class extends Controller {
  connect() {
    this.observer = new MutationObserver((mutationList)=> {
      for(const mutation of mutationList) {
        if(
          // Make sure we only run the regexp if there's no child elements
          mutation.target.childElementCount == 0 &&
          // Test for the absence of any non-whitespace character
          !/\S/.test(mutation.target.innerHTML)
        ) {
          mutation.target.innerHTML = '';
        }
      }
    });
    this.observer.observe(this.element, { childList: true });
  }

  disconnect() {
    this.observer.disconnect();
  }
}
