import { Controller } from "stimulus";

/**
 * Controller that previews an image selected through a `type="file"` field
 */
export default class extends Controller {
  static targets = ["input", "preview"]

  connect() {
    this.element.addEventListener('change', (event) => {
      if (event.target == this.inputTarget) {
        renderThumbnail(this.inputTarget, this.previewTarget);
      }
    })
  }
}

function renderThumbnail(inputElement, imgElement) {
  var reader = new FileReader();
  reader.onload = function () {
    var dataURL = reader.result;
    imgElement.src = dataURL;
  };
  reader.readAsDataURL(inputElement.files[0]);
}
