/** @jsx h */
import { h } from 'preact'

export default function({min = 0, max = 100, text, progress,...attributes}) {
  return <span {...attributes}>
    <span class="progress-bar progress-bar--bright">
        <span class="progress-bar__progress"
        style={`width: ${progressPercentage(progress, {min,max})}%`}
        role="progressbar"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={progress} />
    </span>
    <small>{text !== false ? text || `${progressPercentage(progress, {min,max, decimals: 0})}%` : null}</small>
  </span>
}

function progressPercentage(progress, {min,max, decimals = 2}) {
  return (100 * ((progress - min) / (max - min))).toFixed(decimals);
}
