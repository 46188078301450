/**
 * Helpers for manipulating hex colors
 */
export function opacify(colorHex) {
  return isTransparent(colorHex) ? colorHex.slice(0, -2) : colorHex;
}

export function makeTransparent(colorHex, transparency = '4d') {
  // Make it update the transparency if the color was already transparent
  return opacify(colorHex) + transparency;
}

export function isTransparent(colorHex) {
  return colorHex.length === 9;
}
