import { Controller } from "stimulus";

const TEXT_NODE_TYPE = 3;

export default class extends Controller {
  static targets = ["button"]

  connect() {
    this.buttonTargets.forEach(button => {
      button.addEventListener("keydown", function (event) {
        if (event.keyCode == 27) {
          const siblings = getAllSiblingsAndSelf(button);
          siblings.forEach(element => {
            element.classList.remove('dashboard-callout--unfocused');
          });
          button.blur();
        }
      });
    });
  }

  focusElement(event) {
    const siblings = getAllSiblingsAndSelf(event.currentTarget)
    siblings.forEach(element => {
      if (element != event.currentTarget) {
        element.classList.add('dashboard-callout--unfocused');
      }
    });
  }

  unFocusElement(event) {
    const siblings = getAllSiblingsAndSelf(event.currentTarget)
    siblings.forEach(element => {
      element.classList.remove('dashboard-callout--unfocused');
    });
  }
}

function getAllSiblingsAndSelf(elem) {
  var sibs = [];
  elem = elem.parentNode.firstChild;
  do {
      if (elem.nodeType === TEXT_NODE_TYPE) continue;
      sibs.push(elem);
  } while (elem = elem.nextSibling)
  return sibs;
}