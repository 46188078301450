/** @jsx h */
import classNames from 'classnames';
import { h } from 'preact'
import FileUpload from './file_upload.jsx';
import { useState } from 'preact/hooks';
import { DisclosureToggle } from './disclosure_toggle.jsx';
import { CancelAllAction } from './cancel_all_action.jsx';

export default function UploadOverlay({ state }) {

  const files = getFiles(state);

  const [expanded, setExpanded] = useState(true);

  return <article
    role="region"
    aria-label="Uploads"
    data-expanded={expanded}
    class={classNames("upload-overlay", { hidden: !files.length })}
  >
    <header class="flex items-center px-4 py-3">
      <OverlayTitle files={files} class="mr-auto" />
      <DisclosureToggle
        class="ml-3"
        expanded={expanded} onClick={() => setExpanded(!expanded)}
        aria-controls="overlay-body"
      />
      <CancelAllAction class="ml-1" />
    </header>
    {/*
      Extra element around the overlay to ensure it collapses to nothing
      and not to just its padding.
    */}
    <div class="upload-overlay__body" id="overlay-body">
      <OverlayBody files={files} class="p-4 border-t border-white border-opacity-20" />
    </div>
    <ReloadLink files={files} class="px-4 py-3 border-t border-white border-opacity-20" />
  </article>
}

function ReloadLink({ files, ...attributes }) {
  const libraryPath = "/users/usage"
  const currentPath = window.location.pathname;
  const isLibraryPath = currentPath === libraryPath;

  if (hasCompletedFiles(files)) {
    return (
      <a
        {...attributes}
        className={classNames(
          "block text-center hover:underline no-default text-inherit",
          attributes["class"]
        )}
        href={libraryPath}
      >
        {isLibraryPath ? "Reload your library" : "Go to library"}
      </a>
    );
  }
  return null;
}

function OverlayTitle({ files, ...attributes }) {
  return <h2
    {...attributes}
    class={
      classNames("h4 font-normal m-0", attributes['class'])
    }
    aria-live="polite"
  >{overlayTitleText({ files })}</h2>
}

function overlayTitleText({ files }) {
  if (hasUploadingFiles(files)) {
    const uploadsRemaining = files.filter(file => !file.progress.uploadComplete);
    const uploadsInProgress = uploadsRemaining.filter(file => !!file.progress.uploadStarted);
    return `Uploading ${uploadsInProgress.length} of ${uploadsRemaining.length}`;
  } else {
    const uploadsCompleted = files.filter(file => file.progress.uploadComplete);
    return `${uploadsCompleted.length} files uploaded`
  }
}

function OverlayBody({ files, ...attributes }) {
  return <ul {...attributes} class={classNames("file-upload-list", attributes["class"])}>
    {files.reverse().map(file => <li key={file.id}><FileUpload file={file} /></li>)}
  </ul>;
}

function getFiles(state) {
  return Object.values(state.files);
}

function hasUploadingFiles(files) {
  return files.some(file => !file.progress.uploadComplete);
}

function hasCompletedFiles(files) {
  return files.every(file => file.meta.apiCallsFinished);
}
