import { Controller } from "stimulus";
import { formatDistanceToNow } from "date-fns";

export default class extends Controller {
  static values = {
    datetime: String,
    refreshInterval: Number,
    includeSeconds: Boolean,
    suffix: String,
    prefix: String,
    past: String,
  };

  initialize() {
    this.isValid = true;
  }

  connect() {
    this.load();

    if (this.hasRefreshIntervalValue && this.isValid) {
      this.startRefreshing();
    }
  }

  disconnect() {
    this.stopRefreshing();
  }

  load() {
    const datetime = this.datetimeValue;
    const date = Date.parse(datetime);
    const options = {
      includeSeconds: this.hasIncludeSecondsValue,
      addSuffix: this.hasAddSuffixValue,
      locale: this.locale,
    };

    if (Number.isNaN(date)) {
      this.isValid = false;

      console.error(
        `[stimulus-timeago] Value given in 'data-timeago-datetime' is not a valid date (${datetime}). Please provide a ISO 8601 compatible datetime string. Displaying given value instead.`
      );
    }

    const past = new Date().getTime() > date;
    const renderPastString = past && this.hasPastValue;

    if (this.lastRunWasPast === undefined) {
      this.lastRunWasPast = past;
      setTimeout(this.triggerReloadEvent, 10000);
    } else if (past !== this.lastRunWasPast) {
      console.log("Reloading");
      this.lastRunWasPast = past;
      this.triggerReloadEvent();
    }

    this.element.dateTime = datetime;
    this.element.innerHTML = this.isValid
      ? renderPastString
        ? this.pastValue
        : this.timeAgoString(date, options)
      : datetime;
  }

  triggerReloadEvent() {
    window.dispatchEvent(new Event("reloaddatatable"));
  }

  timeAgoString(date, options) {
    return [
      this.prefixValue,
      formatDistanceToNow(date, options),
      this.suffixValue,
    ]
      .filter((v) => v !== undefined)
      .join(" ");
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load();
    }, this.refreshIntervalValue);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }
}
