import { Controller } from "stimulus";
import tippy from "tippy.js";

/**
 * Base controller for mounting [Tippy](https://atomiks.github.io/tippyjs/)
 * tooltips and popover. Configuration is adjusted for each in the respective
 * TooltipController and PopoverController
 */

export default class TippyController extends Controller {

  static values = {
    trigger: String,
    placement: String,
    content: String,
    contentSelector: String,
    arrow: Boolean,
    appearance: String
  }

  static allTippyInstances = [];

  get trigger() {
    return this.triggerValue;
  }

  get arrow() {
    return this.hasArrowValue ? this.arrowValue : this.defaultArrow;
  }

  get defaultArrow() {
    return false;
  }

  get placement() {
    return this.placementValue;
  }

  get appearance() {
    return this.appearanceValue
  }

  get content() {
    return this.contentValue ||
      (this.contentSelectorValue && document.querySelector(this.contentSelectorValue)) ||
      this.element.nextElementSibling
  }

  connect() {
    this.instance = tippy(this.element, this.tippyOptions);
  }

  get tippyOptions() {
    return {
      allowHTML: true,
      interactive: true,
      trigger: this.trigger,
      placement: this.placement,
      arrow: this.arrow,
      touch: ["hold", 500],
      content: () => {
        const content = this.content;
        // Sometimes, especially for tooltips, the content will just be a piece of text
        if (content instanceof Element) {
          content.classList.remove("hidden");
        }
        return content;
      },
      popperOptions: {
        modifiers: [
          {
            name: "flip",
            // Left in case the screen is small and zoomed in
            // to let the popover get on the side where there's
            // more space
            options: {
              fallbackPlacements: ["top-end", "left"],
              padding: { bottom: 80 },
            },
          },
        ],
      },
      onCreate(instance) {
        TippyController.allTippyInstances.push(instance)
      },
      // Setup stimulus controller on the overlay
      // so that content within can close it on click
      // tippy already sets a `_tippy` property on the `Element`
      // That the controller will be able to retrieve
      onMount: () => {
        if (!hasDOMToken(this.instance.popper, "data-controller", "tippy-content")) {
          prependDOMToken(this.instance.popper, "data-controller", "tippy-content");
        }

        // Add a class for opting out form the default handling of `aria-disclosure`
        this.instance.popper.classList.add("tippy-root", "aria-disclosure-content--no-default");
        this.instance.popper.dataset.appearance = this.appearance;
      }
    }
  }

  hide() {
    this.instance.hide();
  }
}



function hasDOMToken(element,attributeName, token) {
  const attributeValue = element.getAttribute(attributeName);

  return attributeValue && attributeValue.indexOf(token) !== -1;
}

function prependDOMToken(element, attributeName, token) {
  const attributeValue = element.getAttribute(attributeName);

  element.setAttribute(
    attributeName,
    [token, attributeValue].filter(Boolean).join(" ")
  );
}
