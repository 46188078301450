import XHRUpload from "@uppy/xhr-upload";
import {
  apiHeaders,
  apiRoot,
  authenticate,
} from "../../../../utilities/authentication";
import { request } from "../../../../utilities/api";

/**
 * Custom Uppy uploader based on the library's XHRUpload
 * plugin and wrapping its `upload` method with the creation
 * of the necessary Carescribe resources.
 * Rather than using [Uppy's `preProcessor` hook](
 * https://uppy.io/docs/writing-plugins/#Upload-Hooks)
 * this allows us to:
 *  - have the wait for the Carescribe API calls to be per file
 *    rather than per batch of files being processed
 *  - benefit of any tricky issues around XHR upload
 *    that'd be solved by their plugin already
 */
export default class TranscriptionUploader extends XHRUpload {
  constructor(uppy, opts = {}) {
    super(uppy, {
      method: "PUT",
      formData: false,
      ...opts,
    });
    this.id = opts.id || "Caption.Ed Create Transcription";
    this.type = "caption.ed";
  }

  async upload(file, current, total) {
    try {
      await this.getAccessToken();
      // Create the media resource on the server
      const media = await this.createMedia(file);
      file.xhrUpload = {
        endpoint: media.data.attributes.signed_url_for_upload,
      };
      await super.upload(file, current, total);
      // Create and start the transcription
      // TODO: It'd be great if this could be a unique call
      // rather than 2. Less chances to risk the transcription
      // not starting because the second request fails
      const transcription = await this.createTranscription(media);
      await this.transcribeTranscription(transcription);
      uppy.setFileMeta(file.id, { apiCallsFinished: true })
    } catch (error) {
      this.uppy.emit("upload-error", file, error);
      throw error;
    }
  }

  async getAccessToken() {
    this.accessToken = await authenticate();
  }

  createMedia(file) {
    return request(`${apiRoot()}/library_uploads`, {
      method: "POST",
      headers: apiHeaders(this.accessToken),
      body: JSON.stringify({
        media: {
          content_type: file.type,
          original_file_name: file.name,
        },
      }),
    }).then((res) => res.json());
  }

  createTranscription(media) {
    return request(`${apiRoot()}/transcriptions`, {
      method: "POST",
      headers: apiHeaders(this.accessToken),
      body: JSON.stringify({
        source: {
          type: "media",
          id: media.data.id,
          transcription_source: "upload",
        },
      }),
    }).then((response) => response.json());
  }

  transcribeTranscription(transcription) {
    return request(
      `${apiRoot()}/transcriptions/${transcription.data.id}/transcribe`,
      {
        method: "PUT",
        headers: apiHeaders(this.accessToken),
      }
    );
  }
}
