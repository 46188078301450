import { BasePlugin } from "@uppy/core";
import { dispatchNoticeEvent } from "../../notice_events_controller";

export default class ErrorNotification extends BasePlugin {
  constructor(uppy, opts = {}) {
    super(uppy, opts);
    this.id = opts.id || "Caption.Ed Error notification";
    this.type = "caption.ed";

    this.element = opts.element || document.body;

    // Bind methods being used as event listeners to they access `this`
    this.checkErrorsOnCompletion = this.checkErrorsOnCompletion.bind(this);
    this.notifyError = this.notifyError.bind(this);
  }

  checkErrorsOnCompletion({successful, failed}) {
    if (failed.length) {
      if (successful.length) {
        this.notify({message:`
          Sorry, we failed to upload some of the files you selected:
          ${failed.map((file) => file.name).join(", ")}.
        `});
      } else {
        this.notifyError()
      }
    }
  }

  notifyError() {
    this.notify({
      message: "Sorry we failed to upload the files you selected."
    })
  }

  notify({source, message, type = "error", ...notificationOptions}) {
    dispatchNoticeEvent(source || this.element, {
      type,
      message: uploadErrorMessage(message),
      toast: true,
      ...notificationOptions
    });
  }

  install() {
    this.uppy
      .on('complete', this.checkErrorsOnCompletion)
      .on('error', this.notifyError)
  }

  uninstall() {
    this.uppy
      .off('complete', this.checkErrorsOnCompletion)
      .off('error', this.notifyError)
  }
}

function uploadErrorMessage(message) {
  return `<div>
    <p class="font-semibold text-gray-900">Upload error</p>
    ${message}
  </div>`
}
