import { dispatchNoticeEvent } from "../controllers/design_system/notice_events_controller";
/*
 * Prevent double activation of clickable elements marked with `aria-disabled`
 * and ties it to Turbo frames lifecycle
 */
document.addEventListener(
  "click",
  (event) => {
    if (event.target.closest("[aria-disabled]")) {
      // Both because Turbo seems to intercept the clicks very early :/
      event.preventDefault();
      event.stopImmediatePropagation();

      // TODO: If needs arise, this could be the place to
      // implement getting the type and message from the element's
      // data attributes, similarly to how the notice_events controller
      // does for its `notifyXYZ` errors
      dispatchNoticeEvent(event.target, {
        type: "warning",
        message:
          "This action is already underway. Please wait for its completion.",
      });
    }
  },
  true
);

// Prevent double submission of Turbo forms
document.addEventListener("turbo:submit-start", (event) => {
  // TODO: If needs arise, this would be the place
  // to look for a specific data attribute (eg. data-multiple-activation="true") to allow
  // multiple submissions for specific actions.
  event.detail.formSubmission.submitter.setAttribute("aria-disabled", "");
});

document.addEventListener("turbo:submit-end", (event) => {
  event.detail.formSubmission.submitter.removeAttribute("aria-disabled");
});
