export const getCSRFToken = () => {
  const element = document.querySelector('meta[name="csrf-token"]');
  if (!element) return undefined;

  return element.getAttribute("content");
};

/* 
  Thin wrapper around fetch that throws
  when the response has an error HTTP status code (4XX or 5XX)
*/
export function request(...args) {
  return fetch(...args)
    .then(res => {
      if (!res.ok) throw res;
      return res;
    })
}
