import classNames from 'classnames';
import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['notices', 'toasts']

  connect() {
    this.element.addEventListener('notice', (event) => {
      // Handle nested notice events, like when one is within a modal
      if (!event.defaultPrevented) {
        event.preventDefault();
        // `notice` renders the HTML for the notice based on a `{type, message}` object
        const noticeHtml = notice({
          ...event.detail,
          dismissible: isNoticeDismissible(event.target, event.detail.type)
        });
        this.noticesTarget.innerHTML = noticeHtml;
      }
    });
  }

  notifyInfo(event) {
    this.notify(event, 'info');
  }

  notifyWarning(event) {
    this.notify(event, 'warning');
  }

  notifySuccess(event) {
    this.notify(event, 'success')
  }

  notifyError(event) {
    this.notify(event, 'error')
  }

  notify(event, type = DEFAULT_TYPE) {
    const noticeHtml = notice({
      type,
      message: noticeMessage(event.target, { type }),
      dismissible: isNoticeDismissible(event.target, { type }),
    });
    this.noticesTarget.innerHTML = noticeHtml;
  }

  clear() {
    this.noticesTarget.innerHTML = '';
  }
}

// Read notice information from the element triggering the action
function noticeMessage(element, {type}) {
  return element.dataset[`${type}NoticeMessage`] || element.dataset['noticeMessage'];
}

function isNoticeDismissible(element, {type}) {
  return element.dataset[`${type}NoticeDismissible`] !== "false";
}

// Allow custom code to dispatch notice, from fetch requests or other
export function dispatchNoticeEvent(element, detail) {
  const event = new CustomEvent("notice", {
    bubbles: true,
    cancelable: true,
    detail
  });
  element.dispatchEvent(event);
}

// Allow testing in development mode
if (process.env.NODE_ENV == 'development') {
  window.dispatchNoticeEvent = dispatchNoticeEvent;
}

const ICONS = {
  success: require("../../../../node_modules/@phosphor-icons/core/assets/regular/check-circle.svg?raw"),
  info: require("../../../../node_modules/@phosphor-icons/core/assets/regular/info.svg?raw"),
  warning: require("../../../../node_modules/@phosphor-icons/core/assets/regular/warning-circle.svg?raw"),
  error: require("../../../../node_modules/@phosphor-icons/core/assets/regular/warning-circle.svg?raw"),
};

const DEFAULT_TYPE = 'info';

function notice({type = DEFAULT_TYPE, message, dismissible, classes}) {
  return `
  <div class="notice notice--${type} ${classes}" ${dismissible ? 'data-controller="dismiss"' : ''}>
    ${noticeIconFor(type)}

    <div>${message}</div>
    ${dismissible ? noticeDismissButton(type) : ''}
  </div>
  `;
}

function noticeDismissButton(type = DEFAULT_TYPE) {
  return `<a class="action--icon action-appearance rounded-xs" href="/users/usage" data-action="dismiss#dismiss">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" role="presentation" focusable="false" width="24" height="24">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
    </svg>

    <span>
      Dismiss ${type}
    </span>

  </a>`;
}

const DISMISS_ICON = require("../../../../node_modules/@phosphor-icons/core/assets/regular/x.svg?raw");

function noticeIconFor(type) {
  return ICONS[type] || ICONS[DEFAULT_TYPE];
}
