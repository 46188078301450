import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Timeago from "stimulus-timeago"

import designSystemControllers from './design_system';

const application = Application.start()
const context = require.context(".", true, /\.js$/)
application.load(definitionsFromContext(context))
// To avoid the design system controllers to be prefixed by `design-system--`
// the `require.context` for them needs to happen from `design_system/index.js`
application.load(designSystemControllers())

application.register("timeago", Timeago)
