import { Controller } from "stimulus";
import Autocomplete from "@tarekraafat/autocomplete.js";

export default class extends Controller {

  connect() {

    /* Enhance the current element and store the instance and input from Autocomplete */
    Object.assign(this, enhanceSelectElement(this.element));

    /*
      Needs to happen after the value of the input is updated
      so that the right value gets backed up for reset after one
      is selected 
    */
    setupResetOnBlur(this.input);

    /* Adds extra interactions to help render the list */
    this.input.addEventListener("focus", () => {
      this.instance.start();
    });
    this.input.addEventListener("keydown", (event) => {
      if (!this.instance.isOpen) {
        if (event.key == 'ArrowDown') {
          this.instance.start();
          this.instance.goTo(0);
        } else if (event.key == 'ArrowUp') {
          this.instance.start();
          this.instance.goTo(-1);
        }
      }
    });
  }
}

function enhanceSelectElement(select) {
  const input = createAutocompleteInput();
  replaceSelectWithAutocompleteInput(select, input);

  const instance = new Autocomplete({
    selector: `#${input.id}`,
    placeHolder: select.getAttribute("placeholder"),
    data: dataFromSelectOptions(select.options),
    threshold: 0,
    resultsList: {
      maxResults: 1000,
      element: (list, data) => {
        if (!data.results.length) {
          // Create "No Results" message list element
          const message = document.createElement("div");
          message.setAttribute("class", "autocomplete__no-result");
          // Add message text content
          message.innerHTML = `<span>Found No Results for "${data.query}"</span>`;
          // Add message list element to the list
          list.appendChild(message);
        }
      },
      noResults: true,
    },
    resultItem: {
      element: (item, data) => {
        if (data.value.optionIndex == select.selectedIndex) {
          item.setAttribute("aria-selected", true);
        }

        // Fix vocalisation of option. Unfortunately
        // `aria-label` seemed to mess up with VoiceOver on Safari
        item.innerHTML = `
          <span aria-hidden="true">${data.match}</span>
          <span class="sr-only">${data.value.label}</span>
        `;
      },
      highlight: {
        render: true,
      },
    }, searchEngine(query, record) {
      
      if (input.hasAttribute("data-autocomplete-opened")) {
        return instance.search(query, record, {
          searchEngine: "strict",
          diacritics: true,
          highlight: {
            render: true,
          },
        }) || record
      }
     
      return instance.search(query, record, {
        searchEngine: 'strict',
        diacritics: true,
        highlight: {
          render: true
        }
      })
    }
  });

  // Trigger a different search behaviour on first opening
  // so the list shows all options
  const originalStart = instance.start;
  instance.start = function() {
    input.setAttribute("data-autocomplete-opened", "");
    originalStart.apply(this, arguments);
  }

  input.addEventListener("results", () => {
    input.removeAttribute("data-autocomplete-opened", "");
  });

  /* Synchronise selection both with the input and with the select */
  input.addEventListener("selection", (event) => {
    const selection = event.detail.selection.value;
    select.selectedIndex = selection.optionIndex;
    input.value = selection.label;
  });

  return { input, instance };
}

function replaceSelectWithAutocompleteInput(select, input) {
  /* Move ID from `<select>` to `<input>` so label points to the right place */
  input.id = select.id;
  select.id = select.id + "_select";
  /* Move along the field's description and value */
  input.setAttribute(
    "aria-describedby",
    select.getAttribute("aria-describedby")
  );
  if (select.hasAttribute('aria-invalid')) {
    input.setAttribute('aria-invalid', 'true')
  }
  input.disabled = select.disabled
  input.value = select.selectedOptions[0]?.textContent;

  /* And swap the two fields' visibility */
  select.setAttribute("hidden", true);
  select.parentElement.insertBefore(input, select);
}

function setupResetOnBlur(input) {
  input.addEventListener("selection", () => {
    input.setAttribute("data-value", input.value);
  });
  input.addEventListener("focus", () => {
    input.setAttribute("data-value", input.value);
  });
  input.addEventListener("blur", () => {
    input.value = input.getAttribute("data-value") || "";
  });
}

function createAutocompleteInput() {
  const input = document.createElement("input");
  input.setAttribute("class", "select-like");
  input.setAttribute("type", "text");
  input.setAttribute("spellcheck", "false");
  input.setAttribute("autocorrect", "off");
  input.setAttribute("autocomplete", "off");
  input.setAttribute("autocapitalize", "off");
  return input;
}


function dataFromSelectOptions(options) {
  return {
    /* Turn the NodeList into an actual Array so we can `map` and `filter` */
    src: [...options]
      /* Create a little datastructure to keep the option index
         as HTMLSelectElement relies on `selectedIndex` for setting
         it options */
      .map((option, index) => ({
        label: option.textContent,
        optionIndex: index,
      }))
      /* Filter out the empty value as there's no sense suggesting it.
         Important that it happens after mapping the option so that
         indices are not off by 1 */
      .filter((suggestion) => suggestion.label),
    keys: ["label"],
    cache: true,
  };
}
