/** @jsx h */
import { UIPlugin } from '@uppy/core'
import { h } from 'preact'
import UploadOverlay from '../components/upload_overlay.jsx';
import UppyContext from '../components/uppy_context';

export default class Overlay extends UIPlugin {
  constructor(uppy, opts = {}) {
    super(uppy, opts);
    this.id = opts.id || "Caption.Ed Overlay";
    this.type = "caption.ed";

    this.expandOverlay = this.expandOverlay.bind(this);
    this.scrollUploadList = this.scrollUploadList.bind(this);
  }

  render(state) {
    return <UppyContext.Provider value={this.uppy}>
      <UploadOverlay state={state} />
    </UppyContext.Provider>
  }

  expandOverlay() {
    const toggle = this.opts.target.querySelector('.disclosure-toggle');
    if (toggle && toggle.getAttribute('aria-expanded') !== "true") {
      toggle.click();
    }
  }

  scrollUploadList() {
    const list = this.opts.target.querySelector('.file-upload-list');
    if (list) {
      list.scrollTop = 0;
    }
  }

  install() {
    const { target } = this.opts;
    if (target) {
      this.mount(target, this);
      this.uppy.on('files-added', this.expandOverlay);
      this.uppy.on('file-added', this.scrollUploadList);
    }
  }

  uninstall() {
    this.unmount();
    this.uppy.off('files-added', this.expandOverlay)
    this.uppy.on('file-added', this.scrollUploadList);
  }
}
