import { shouldNotHandleFocus } from "../../utilities/focus_visible";
import TooltipController from "./tooltip_controller";

export default class extends TooltipController {

  get tippyOptions() {
    return {
      ...super.tippyOptions,
      touch: true
    };
  }
}
