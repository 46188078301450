import { Controller } from "stimulus";
import {LOADER_ICON} from './icons';
import { createElement } from "./turbo_frame_loading_controller";

// Slight timeout before triggering the announcement
// to avoid being too verbose when the loading goes super quick
// See Sarah Higley demo: https://cdpn.io/smhigley/debug/GRvOevx
const ANNOUNCEMENT_TIMEOUT_DURATION = 200;

const LOADER_ICON_MARKUP = LOADER_ICON.replace('<svg', '<svg class="loading-status-icon"');

export default class extends Controller {
  static values = {
    status: String
  }

  get observer(){ 
    if (!this._observer) {
      this._observer = this.createObserver();
    }
    return this._observer;
  }

  get iconElement() {
    if (!this._iconElement) {
      this._iconElement = createElement(LOADER_ICON_MARKUP);
    }
    return this._iconElement;
  }

  get announcerElement() {
    return this.element.previousElementSibling;
  }

  connect() {
    this.observer.observe(this.element, {attributes: true})
    this.updateStatus();
  }

  createObserver() {
    return new MutationObserver(mutationList => {
      for(const mutation of mutationList) {
        this.handleMutation(mutation);
      }
    });
  }

  handleMutation({type, attributeName, target}) {
    if (type == "attributes" && attributeName == "aria-disabled") {
      this.updateStatus();
    }
  }

  updateStatus() {
    if (this.element.hasAttribute("aria-disabled")) {
      this.showIcon();
      this.announceLoading();
    } else {
      this.hideIcon();
      this.maybeCancelAnnouncement();
    }
  }

  showIcon() {
    this.element.insertAdjacentElement("afterbegin", this.iconElement);
  }

  hideIcon() {
    this.iconElement.parentElement.removeChild(this.iconElement);
  }

  announceLoading() {
    this.annoucementTimeout = setTimeout(() => {
      this.announcerElement.textContent = this.statusValue;
    }, ANNOUNCEMENT_TIMEOUT_DURATION);
  }

  maybeCancelAnnouncement() {
    clearTimeout(this.annoucementTimeout);
    this.announcerElement.textContent = "";
  }
}
