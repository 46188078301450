import { html, render } from 'lit';
import { opacify } from '../hex_colors';

/**
 * Plugin for rendering a custom legend inside the `legendTarget`
 * of a Stimulus controller
 * @param {HTMLUListElement|HTMLOListElement} listElement
 * @returns
 */
//https://www.chartjs.org/docs/latest/samples/legend/html.html
export default function HTMLLegendPlugin(listElement) {
  return {
    start: (chart) => {
      if (chart?.options?.plugins?.legend) {
        chart.options.plugins.legend.display = false
      }
    },
    afterUpdate: (chart) => {
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      const listItems = items.map((item) =>
        legendItem(item, { 
          chart,
        })
      );

      render(listItems, listElement);
    },
  };
}

function legendItem(item, { chart }) {
  const inputId = `legend-${chart.id}-${legendIndex(item)}`;

  return html`<li class="checkbox">
    <input
      id="${inputId}"
      type="checkbox"
      ?checked="${!item.hidden}"
      class="rounded"
      style="
        background-color: ${opacify(item.fillStyle)}; 
        border-color: ${opacify(item.fillStyle)}
      "
      value="${legendIndex(item)}"
      data-action="chartjs#toggleLegendItem"
      data-legend-type="${legendType(item)}"
    />
    <label for="${inputId}">${item.text}</label>
  </li>`;
}

function legendType(item) {
  return isDatasetItem(item) ? "dataset" : "data";
}

function legendIndex(item) {
  // Can't use `item.datasetIndex || item.index` as it would use `index`
  // when `datasetIndex` is 0
  return isDatasetItem(item) ? item.datasetIndex : item.index;
}

function isDatasetItem(item) {
 return "datasetIndex" in item
}
