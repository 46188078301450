import { Controller } from "stimulus";
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
  static targets = ["input", "button", "form"]

  connect() {
    this.buttonTarget.disabled = true;

    intlTelInput(this.inputTarget, {
      initialCountry: "GB"
    });

    this.inputTarget.addEventListener('input', (event) => {
      if (this.inputTarget.value == "") {
        this.buttonTarget.disabled = true;
      } else {
        this.buttonTarget.disabled = false;
      }
    });

    this.buttonTarget.addEventListener('click', (event) => {
      event.preventDefault();
      if (this.inputTarget.value.startsWith('+440')) {
        this.inputTarget.value = this.inputTarget.value.replace('+440', '+44');
      }
      this.formTarget.submit();
    });
  } 
}