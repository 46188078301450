import { Controller } from "stimulus";
import A11yDialog from "a11y-dialog";
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";

/**
 * WeakMap linking elements to their associated A11yDialog object
 * to keep track tidily while allowing garbage collection when
 * the elements disappear
 * @private
 */
const INSTANCES = new WeakMap();

export default class extends Controller {

  static targets = ["pageContent"]

  toggle(event) {

    // Allow ctrl-clicks and other specials ways of clicking the link
    // to function as before
    if (hasModifiers(event)) return;
    
    const id = event.currentTarget.getAttribute("aria-controls");
    const target = document.getElementById(id);

    if(!target) {
      if (process.env.NODE_ENV == 'development') {
        console.error(`No target with this id exists to open dialog`, id, event.currentTarget)
      }
      return;
    }

    // Only prevent default after ensuring there's a target dialog to open
    // so that dialogs hooked on links follow the link in case there's no dialog
    // on the page
    if (shouldPreventDefault(event.currentTarget)) event.preventDefault();
    
    const dialog = this.instanceFor(target);
    if (dialog.shown) {
      dialog.hide();
    } else {
      dialog.show();
    }
  }

  close(event) {
    if (shouldPreventDefault(event.currentTarget)) event.preventDefault();

    const dialogEl = event.target.closest('[role="dialog"]');
    if (dialogEl) {
      const dialog = this.instanceFor(dialogEl);
      dialog.hide();
    }
  }

  instanceFor(target){
    return INSTANCES.get(target) || this.createDialog(target);
  }

  createDialog(target) {
    const dialog = new A11yDialog(target);
    INSTANCES.set(target, dialog);
    target.setAttribute('tabindex', '-1');

    dialog.on('show', () => {
      this.pageContentTargets.forEach(element => 
        element.setAttribute('aria-hidden', 'true')
      );
      disableBodyScroll(target);
      // Avoid the page scrolling as the dialog takes focus
      whileMaintainingScroll(() => {
        target.focus();
      });
    });
    dialog.on('hide', () => {
      this.pageContentTargets.forEach(element => 
        element.removeAttribute("aria-hidden")
      )
      enableBodyScroll(target);
    });
    dialog.on('destroy', () => {
      this.pageContentTargets.forEach((element) =>
        element.removeAttribute("aria-hidden")
      );
      enableBodyScroll(target);
    })

    if (target.dataset.dialogMediaQuery) {
      const media = window.matchMedia(target.dataset.dialogMediaQuery);
      media.addEventListener('change', event => {
        if (!event.matches && dialog.shown) {
          dialog.hide();
        }
      });
    }

    return dialog;
  }
}

function shouldPreventDefault(el) {
  return el.getAttribute('data-aria-dialog-prevent-default') !== "false"
} 

/**
 * Checks whether the given event has any modifier (Ctrl, Shift, Alt, meta)
 * or is a right click
 * @param {MouseEvent} event
 */
export function hasModifiers(event) {
  return (
    event.which > 1 ||
    event.metaKey ||
    event.ctrlKey ||
    event.shiftKey ||
    event.altKey
  );
}

function whileMaintainingScroll(fn) {
  const currentScrollY = window.pageYOffset;
  const currentScrollX = window.pageXOffset;
  fn();
  window.scrollTo(currentScrollX, currentScrollY);
}
