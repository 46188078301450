import { Controller } from "stimulus";

export default class extends Controller {

  static values = {
    remaining: Number,
    percentage: Number
  }

  static targets = ['progressbar', 'percentage', 'duration'];

  connect() {
    const start = Date.now();
    const tick = () => {
      const now = Date.now();
      // Get the delta in seconds
      const delta = (now - start) / 1000;

      // Early scheduling of a new tick or exit if complete
      if (delta < this.remainingValue) {
        this.requestAnimationFrame(tick);
      }

      // Cap progress to 1 to avoid visual glitches
      const progress = Math.min(delta / this.remainingValue, 1);

      // Time flows linearly so we're not easing it
      const newRemaining = this.remainingValue * (1 - progress);
      this.durationTarget.textContent = formatDuration(newRemaining);

      // We want to give a good impression on the upload progress
      // so we'll ease it so it's quick at the start and slows down
      // little by little
      const easedProgress = polyEaseOut(progress);

      const newPercentage = this.percentageValue + (100 - this.percentageValue) * easedProgress;
      this.progressbarTarget.style.width = `${newPercentage.toFixed(2)}%`;
      this.percentageTarget.textContent = `${newPercentage.toFixed(0)}%`;
    }
    this.requestAnimationFrame(tick);
  }

  disconnect() {
    cancelAnimationFrame(this.requestId);
  }

  requestAnimationFrame(callback) {
    this.requestId = requestAnimationFrame(callback);
  }
}

function formatDuration(seconds) {
  const minutes = seconds / 60;
  if (minutes < 1) {
    return '<1min';
  } else {
    return `~${Math.round(minutes)}min`;
  }
}

// Non polynomial easing functions can be gotten
// from https://easings.net/
function polyEaseOut(t, {power = 4} = {}) {
  return 1 - (1 - t) ** power;
}
