import { shouldNotHandleFocus } from "../../utilities/focus_visible";
import TippyController from "./tippy_controller";

export default class extends TippyController {

  get trigger() {
    return super.trigger || "mouseenter focus";
  }

  get placement() {
    return super.placement || "bottom";
  }

  get appearance() {
    return super.appearance || "tooltip";
  }

  get tippyOptions() {
    return {
      ...super.tippyOptions,
      // Tooltips are not meant to hold interactive content
      // like links, form fields or buttons. If those need to
      // be shown, please use a popover
      interactive: false,
      onTrigger: (instance, event) => {
        this.show = !(shouldNotHandleFocus(event)
          || isPopoverShown(this.element));
      },
      onShow: () =>  {
        // Ensure only one tooltip is visible at a time
        // This prevents having two tooltips because one is shown on `focus`
        // and the other on `mouseenter`
        replaceVisibleTippy(this.instance, this.constructor);
        // Prevent tooltip to be shown when a popover is displayed
        return this.show;
      },
    };
  }
}

function isPopoverShown(element) {
  return element.getAttribute('aria-expanded') === 'true';
}

function replaceVisibleTippy(instance, context) {
  if (context.visibleTippy) {context.visibleTippy.hide()}
  context.visibleTippy = instance;
}
