/** @jsx h */
import classNames from 'classnames';
import { h } from 'preact'
import CancelAction from './file_upload/cancel_action.jsx';
import FileIcon from './file_upload/file_icon.jsx';
import UploadStatus from './file_upload/upload_status.jsx';

export default function ({file}) {
  return <article
    class={classNames(
      "file-upload", {
      'file-upload--complete': file.progress.uploadComplete
    })}>
    <FileIcon class="file-upload__icon" file={file}/>
    <span class="file-upload__name truncate">{file.name}</span>
    <UploadStatus class="file-upload__status" file={file} />
    <CancelAction class="file-upload__action" file={file} />
  </article>
}
