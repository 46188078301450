import { Controller } from "stimulus"
import {
  get as getButtonText,
  update as updateButtonText,
} from "../utilities/button_text";

export default class extends Controller {
  static targets = ['cardErrors', 'paymentIntent', 'confirmBtn', 'form', 'title', 'pgTitle'];
  static values = {
    publishableKey: String,
    clientSecret: String,
  };

  connect() {
    this.stripe = Stripe(this.publishableKeyValue);
    this.setupStripeCardElement();

    this.confirmBtnTarget.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();

      this.confirmBtnTarget.disabled = true;
      const previousButtonText = getButtonText(this.confirmBtnTarget);
      updateButtonText(this.confirmBtnTarget, 'Processing...');
      
      this.stripe.confirmCardPayment(this.clientSecretValue, {
        payment_method: this.selectedCard || {
          card: this.card,
        },
        setup_future_usage: 'off_session'
      }).then((result) => {
        if (result.error) {
          this.cardErrorsTarget.textContent = result.error.message;
          if (this.hasPgTitleTarget) this.pgTitleTarget.textContent = 'Woops.';
          if (this.hasTitleTarget) this.titleTarget.textContent =
            "Sorry but your card has been declined. Please try again.";
          
          this.confirmBtnTarget.disabled = false;
          updateButtonText(this.confirmBtnTarget, previousButtonText);
        } else if (result.paymentIntent.status === 'succeeded') {
          this.formTarget.submit();
        }
      });
    });
  }

  setupStripeCardElement() {
    const elements = this.stripe.elements();
    const card = elements.create("card");
    card.mount("#card-element");

    card.on("change", (event) => {
      if (event.error) {
        this.cardErrorsTarget.textContent = event.error.message;
      } else {
        this.cardErrorsTarget.textContent = "";
      }
    });

    this.card = card;
  }
}
