import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mobile"]

  toggle() {
    this.mobileTarget.classList.toggle("navbar-mobile--active");
  }
  
  close() {
    this.mobileTarget.classList.remove("navbar-mobile--active");
  }
}
