import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log('submitting')
  }

  disableButton() {
    this.submitButtons().forEach(button => {
      button.disabled = true
      button.textContent = "Sending"
    })
  }

  submitButtons() {
    return this.element.querySelectorAll("button")
  }
}