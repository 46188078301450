import { Controller } from "stimulus";
import {LOADER_ICON} from './icons';

const LOADER_HTML = `
  <div class="loadable-turbo-frame__loader loadable-turbo-frame-loader" aria-live="polite">
    <div class="space-y-2">
      ${LOADER_ICON}
      <p>Loading...</p>
    </div>
  </div>
`

export default class extends Controller {

  get loaderElement() {
    if (!this._loaderElement) {
      this._loaderElement = createElement(LOADER_HTML);
    }
    return this._loaderElement;
  }

  connect() {
    // Insert the loader
    this.element.addEventListener('turbo:before-fetch-request', () => {
      if (this.loaderElement.parentElement) {
        this.loaderElement.parentElement.removeChild(this.loaderElement);
        this.element.classList.remove('hidden');
      }
      requestAnimationFrame(() => {
        this.element.appendChild(this.loaderElement);
      });
    });

    this.element.addEventListener('turbo:fetch-error', () => {
      this.element.classList.add('hidden');
    });
  }
}

export function createElement(html) {
  const root = document.createElement("div");
  root.innerHTML = html;
  return root.firstElementChild; // There may be text or comment nodes
}
