import { Controller } from "stimulus";
import Uppy from "@uppy/core";
import TranscriptionUploader from "./multi_file_upload_controller/uppy/transcription_uploader";
import Overlay from "./multi_file_upload_controller/uppy/overlay.jsx";
import ConfirmNavigation from "./multi_file_upload_controller/uppy/confirm_navigation";
import ErrorNotification from "./multi_file_upload_controller/uppy/error_notification";
import { dispatchNoticeEvent } from "./notice_events_controller";
import { metadata } from "../../utilities/media_files";

const MAXIMUM_SIMULTANEOUS_UPLOADS = process.env.NODE_ENV == 'development'
  ? parseInt(urlQueryParam('maximum_uploads')) || 6 : 6;

export default class extends Controller {

  static values = {
    credit: Number
  }

  static targets = ['overlay'];

  get input() {
    if (!this._input) {
      const input = this._input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute(
        "accept",
        "audio/mpeg, audio/wav, video/mp4, video/mpeg, audio/aac"
      );
      input.setAttribute("hidden", "");
      input.setAttribute("multiple", "");
    }
    return this._input;
  }

  connect() {
    window.uppy = this.uppy = new Uppy({
      // Little development hook to help keep things on screen for longer
      autoProceed: process.env.NODE_ENV === 'development' ? !URLPreventsAutoProceed() : true
    })
    .use(TranscriptionUploader)
    .use(Overlay, {target: this.overlayTarget})
    .use(ConfirmNavigation, {
      message: "Some uploads are still in progress. Are you sure you want to leave?"
    })
    .use(ErrorNotification, {id: 'notifications'})

    // Restore credits locally when an incomplete upload
    // gets removed
    this.uppy.on('file-removed', (file) => {
      if (!file.progress.uploadComplete) {
        this.credit += file.meta.duration
      }
    });

    // Read and cache the initial credit to make it harder to trick
    // the system by simply updating the data attribute in the DOM
    // Best protection will remain having a server call
    // for validating credit, though
    this.credit = this.creditValue;
    // Little help for debugging
    if (process.env.NODE_ENV == 'development') {
      this.credit = parseInt(urlQueryParam('credit')) || this.credit;
    }
  }

  get notificationsPlugin() {
    return this.uppy.getPlugin('notifications');
  }

  pickFile(event) {

    const trigger = event.currentTarget;

    if (this.canPickFiles()) {
      this.input.addEventListener("input", (event) => {

        // Keep a reference to the files as they'll
        // get cleared straight away while the enqueuing
        // will happen asynchronously.
        // Note: event.target.files will be mutated
        // so it needs to be cloned in any case, which
        // is not an issue as we need an actual Array
        // so we can iterate with `forEach`
        const files = Array.from(event.target.files);

        withAriaDisabled(trigger, async () => {
          try {
            // Important to await here so that any errors
            // from asynchronous code will get caught
            const filesMetadata = await Promise.all(files.map(metadata));
            await this.enqueueFiles(files, filesMetadata);
          } catch (error) {
            if (error instanceof UploadError) {
              this.notificationsPlugin.notify({
                source: trigger,
                message: error.message
              });
            } else {
              this.notificationsPlugin.notify({
                source: trigger,
                message: "Sorry, an unexpected error prevented the uploads of the files you selected."
              });
              // Throw the unexpected error so that any top-level error
              // handlers get a hold of it, for ex. if Sentry is set up
              // on the client side
              throw error;
            }
          }
        })

        // https://github.com/transloadit/uppy/blob/f60133b2d4e7f3f5c28132bd7f075a1fc6e3fc28/packages/%40uppy/file-input/src/index.js#L55
        event.target.value = null;
      }, {once: true});

      this.input.click();
    } else {
      this.notificationsPlugin.notify({
        source: trigger,
        type: 'warning',
        message: "Please wait that all your current uploads are completed before adding new files.",
        toast: true
      })
    }
  }

  canPickFiles() {
    return uploadingFiles(uppy).length < MAXIMUM_SIMULTANEOUS_UPLOADS;
  }

  async canEnqueueFiles(files, filesMetadata) {
    if (uploadingFiles(uppy).length + files.length > MAXIMUM_SIMULTANEOUS_UPLOADS) {
      throw new UploadError(
        `Please only enqueue at most ${
          MAXIMUM_SIMULTANEOUS_UPLOADS - uploadingFiles(uppy).length
        } files.`
      );
    }

    const remainingCredit = this.deductCreditFor(filesMetadata);
    if (remainingCredit < 0) {
      throw new UploadError(
        `Sorry, it seems you're missing
        ${Math.round(-1 * remainingCredit)}s
        of credit to upload the selected files.`
      )
    }
    // Keep track of the credit if the uploads actually get enqueued
    // so future uploads use the right starting value
    this.credit = remainingCredit;

    // Avoid having unnecessary code running in production
    // until the server call is actually implemented
    if (process.env.NODE_ENV == 'development') {
      this.doesServerAllowUpload(filesMetadata);
    }
  }

  deductCreditFor(filesMetadata) {
    let creditForFiles = 0;
    for(const {duration} of filesMetadata) {
      if (duration) {
        creditForFiles += duration;
      }
    }
    return this.credit - creditForFiles;
  }

  async doesServerAllowUpload(filesMetadata) {
    // TODO: Send a request to the server to run whichever
    // validations it needs based on the files metadata
    // and throw an error with the relevant message based
    // on its response

    console.log(filesMetadata);
  }

  async enqueueFiles(files, filesMetadata) {
    await this.canEnqueueFiles(files, filesMetadata)

    files.forEach((file, index) => {
      this.uppy.addFile({
        name: file.name,
        type: file.type,
        data: file,
        meta: {
          // Allow uploads of files with the same name
          // but possibly coming from different folder
          // or being uploaded multiple times
          // We don't really need the exact value from the path
          // just something that's different from one file to another
          relativePath: performance.now().toString(),
          // Keep track of the file duration so that it can be
          // added back if users cancel their uploads
          duration: filesMetadata[index].duration,
          // Enable state of other api calls to be held so we don't
          // allow the user to reload the page before they have finished
          apiCallsFinished: false
        },
      });
    });
  }
}

class UploadError extends Error {}

async function withAriaDisabled(element, callback) {
  try {
    element.setAttribute('aria-disabled', '');
    return await callback();
  } finally {
    element.removeAttribute('aria-disabled');
  }
}

function uploadingFiles(uppy) {
  return Object.values(uppy.getState().files).filter(file => !file.progress.uploadComplete)
}

function URLPreventsAutoProceed() {
  return urlQueryParam("uppy_autoproceed") === "false";
}

function urlQueryParam(name) {
  return new URL(window.location).searchParams.get(name);
}
