import TippyController from "./tippy_controller";

export default class extends TippyController {
  static targets = ["submenu"];

  get trigger() {
    return super.trigger || 'click'
  }

  get placement() {
    return super.placement || 'bottom-end'
  }

  get appendOptions() {
    if (this.element.hasAttribute("data-popover-append-popper-to-body")) {
      return { appendTo: document.body }
    } else {
      return {}
    }
  }

  get popperOptions() {
    if (this.element.hasAttribute("data-popover-minimal-offset")) {
      return {
        popperOptions: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 1], // Set popper offset to 1px away from the reference
              }
            }
          ]
        }
      }
    }
    return {}
  }

  get tippyOptions() {

    const hide = () => {
      // Prevent multiple calls as the event gets triggered
      // at a high rate
      window.removeEventListener('scroll', hide, true)
      this.hide()
    }

    return {
      ...super.tippyOptions,
      touch: true,
      ...this.appendOptions,
      ...this.popperOptions,
      onShow({ popper, reference }) {
        // Scroll events don't bubble so we need to grab them at the capture phase
        window.addEventListener('scroll', hide, true);

        // Ensures full-width popovers are at least as long as reference trigger (e.g., button)
        if (reference.hasAttribute("data-full-width-popover")) {
          popper.style.minWidth = reference.getBoundingClientRect().width + "px"
        }
      },
      onHide() {
        window.removeEventListener('scroll', hide, true)
      }
    }
  }

  clickOutside(event) {
    if (event.type == "clickoutside" && !event.detail.event.target.hasAttribute("data-popover-target")) {
      this.instance.hide()
    }
  }
}
