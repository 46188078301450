/**
 * Getter and setters for the text of a button,
 * accounting for whether that button is an `<input type=button|reset|submit>`
 * or an actual `<button>`
 */
export function update(element, text) {
  if (element.tagName == "BUTTON") {
    element.textContent = text;
  } else {
    element.value = text;
  }
}

export function get(element){ 
  if (element.tagName == 'BUTTON') {
    return element.textContent;
  } else {
    return element.value;
  }
}
