import { Controller } from "stimulus"

const config = {
  maxTrickle: 0.05,
  ticklePercentOfRemaining: 0.05,
  leapPercentOfRemaining: 0.2,  
  initialLeap: 0.15,
  trickleFrequency: 2200,
  randomnessFactor: 0.1,
  leakAmount: 1.0001,
  startDelay: 300,
}

export default class extends Controller {
  static targets = ["bar"]
  static values = {
    fakeMode: Boolean,
  };

  
  connect () {
    this.progress = 0
    this.element.addEventListener('start', this.start.bind(this))
    this.element.addEventListener('set', this.set.bind(this))
    this.element.addEventListener('leap', this.leap.bind(this))
    this.element.addEventListener('complete', this.complete.bind(this))
  }

  start() {
    if (this.fakeModeValue) {
      requestAnimationFrame(this.trickle.bind(this))
    }
  }

  shouldTrickle() {
    if (this.lastTrickleTime === undefined) return true
    if (this.lastTrickleTime <= new Date().getTime() - config.trickleFrequency) return true

    return false
  }

  trickle() {
    if (this.shouldTrickle()) {
      this.setProgress(this.incrementAmount(config.ticklePercentOfRemaining))
      this.lastTrickleTime = new Date().getTime()
    }

    if (this.progress < 0.9) this.setProgress(this.progress * config.leakAmount)
    if (this.progress < 1) requestAnimationFrame(this.trickle.bind(this))
  }

  leap () {
    this.setProgress(this.incrementAmount(config.leapPercentOfRemaining))
  }

  set (event) {
    this.setProgress(event.progress)
  }

  complete () {
    this.setProgress(1)
  }

  incrementAmount(percentOfRemaining) {
    if (this.progress < config.initialLeap) return config.initialLeap
    const remaining = 1 - this.progress
    const randomness = (Math.random() - 0.5) * config.randomnessFactor
    const amountToAdd = (remaining * percentOfRemaining) + randomness
    const trickle = amountToAdd > config.maxTrickle ? config.maxTrickle : amountToAdd

    return this.progress + trickle
  }

  setProgress(progress) {
    this.progress = progress > 1 ? 1 : progress > this.progress ? progress : this.progress
    this.barTarget.style.width = `${this.progress * 100}%`
  }
}
