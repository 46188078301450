import { Controller } from "stimulus";

export default class extends Controller {
  dismiss(event) {
    const toggle = this.element.previousElementSibling;
    if (
      // Allows direct invocation for other actions
      event.type != "clickoutside" ||
      // Checks that the clickoutside wasn't on the toggle
      // to avoid cancelling the toggle action
      !toggle.contains(event.detail.event.target)
    ) {
      toggle.setAttribute("aria-expanded", "false");
    }
  }
}
